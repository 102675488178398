import React, { useState, useEffect } from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {
  H1DarkGrey,
  standardWrapper,
  H2DarkGrey,
  B1DarkGrey,
  fontSizer,
  colors,
  B1Lime,
} from "../../../styles/helpers"

import IconCloud from "../../Icons/IconCloud"
import IconGlobe from "../../Icons/IconGlobe"
import IconGraph from "../../Icons/IconGraph"
import IconSpeach from "../../Icons/IconSpeach"

import IconCloudRev from "../../Icons/IconCloudRev"
import IconGlobeRev from "../../Icons/IconGlobeRev"
import IconGraphRev from "../../Icons/IconGraphRev"
import IconSpeachRev from "../../Icons/IconSpeachRev"

import IconCloudLime from "../../Icons/IconCloudLime"
import IconGlobeLime from "../../Icons/IconGlobeLime"
import IconGraphLime from "../../Icons/IconGraphLime"
import IconSpeachLime from "../../Icons/IconSpeachLime"

import IconCloudGrey from "../../Icons/IconCloudGrey"
import IconGlobeGrey from "../../Icons/IconGlobeGrey"
import IconGraphGrey from "../../Icons/IconGraphGrey"
import IconSpeachGrey from "../../Icons/IconSpeachGrey"

gsap.registerPlugin(ScrollTrigger)

const ChooseSection = styled.section`
  padding-top: 5rem;
  padding-bottom: 5rem;

  .wrapper {
    ${standardWrapper};
    margin: 0 auto;
    padding: 0;

    @media (min-width: 768px) {
      margin: 2rem auto;
      padding: 2rem;
    }
  }

  .mainTitle {
    width: 100%;
    text-align: center;

    h2 {
      ${H1DarkGrey}
      span {
        display: inline-block;
      }
    }
  }

  .mainNav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: calc(100%);
    margin: 2rem auto 0;
    transition: all 0.3s ease-in-out;
    text-align: center;

    @media (min-width: 768px) {
      width: calc(100% - 20rem);
      margin: 2rem auto;
    }

    &__item {
      width: calc(25% - 1rem);
      margin: 1rem 0.5rem;
      cursor: pointer;

      @media (min-width: 768px) {
        width: calc(25% - 4rem);
      }

      &--icon {
        width: 100%;
        max-width: 7.5rem;
        margin: 0 auto 2rem;
      }

      &--title {
        p {
          ${H2DarkGrey};
          font-size: 1.4rem;

          @media (min-width: 768px) {
            ${fontSizer(1.8, 2.4, 76.8, 150, 2)};
          }
        }
      }
    }
  }

  .chooseItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 2.5rem auto;
    padding: 2rem;
    transition: all 0.3s ease-in-out;

    @media (min-width: 768px) {
      margin: 7.5rem auto;
      padding: 2rem 3rem;
    }

    &__title {
      width: calc(100%);
      text-align: center;

      @media (min-width: 768px) {
        width: calc(16.665%);
        text-align: right;
      }

      h2 {
        ${H2DarkGrey};
      }
    }

    &__icon {
      width: calc(100%);
      margin-bottom: 3rem;
      padding: 2rem;

      @media (min-width: 768px) {
        width: calc(16.665%);
        margin-bottom: 0;
      }

      & > div {
        max-width: 10rem;
        margin: 0 auto;
      }
    }

    &__content {
      width: calc(100%);
      text-align: center;

      @media (min-width: 768px) {
        width: calc(66.66%);
        text-align: left;
      }

      p {
        ${B1DarkGrey};
      }

      p:last-of-type {
        margin: 0;
      }
    }
  }
`

const SingleNavItem = styled.div``

const SingleChooseItem = styled.div`
  background: ${props =>
    props.itemActive
      ? "linear-gradient(to bottom,rgba(0,95,197, 1),#071d32)"
      : "rgba(255,255,255, 1)"};

  .chooseItem__title {
    h2 {
      color: ${props =>
        props.itemActive ? "rgba(216,223,37, 1)" : "rgba(67,67,67, 1)"};
    }
  }

  .chooseItem__content {
    p {
      color: ${props =>
        props.itemActive ? "rgba(255,255,255, 1)" : "rgba(67,67,67, 1)"};
    }
  }

  .chooseItem__backTop {
    width: 100%;
    margin-top: 2.5rem;
    text-align: right;

    button {
      ${B1Lime};
      display: ${props => (props.itemActive ? "inline-block" : "none")};
      background-color: transparent;
      border: none;
      font-weight: bold;
      cursor: pointer;
    }
  }
`

const Choose = ({ choose }) => {
  const [itemActive, setItemActive] = useState(0)

  useEffect(() => {
    const triggerElement = document.querySelector("#chooseYouServices")
    const titleLetter = [
      ...triggerElement.querySelectorAll(".mainTitle h2 span"),
    ]

    gsap.set(titleLetter, { autoAlpha: 0, y: -30 })
    gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          markers: false,
          start: "top 45%",
          toggleActions: "play none none none",
        },
      })
      .to(titleLetter, {
        autoAlpha: 1,
        y: 0,
        duration: 0.75,
        ease: "power4.out",
        stagger: {
          each: 0.1,
        },
      })
  }, [])

  const newTitle = "Choose Your Service".split("")

  return (
    <ChooseSection id="chooseYouServices">
      <div className="wrapper">
        <div className="mainTitle">
          <h2>
            {newTitle.map((letter, index) => {
              return letter !== " " ? <span key={index}>{letter}</span> : " "
            })}
          </h2>
        </div>
        <div className="mainNav">
          {choose.acf._kon_sercho_services.map((service, index) => {
            const isActiveItem = index === itemActive ? true : false
            const iconDisplay =
              index === 0 ? (
                <IconSpeachLime />
              ) : index === 1 ? (
                <IconGlobeLime />
              ) : index === 2 ? (
                <IconGraphLime />
              ) : (
                <IconCloudLime />
              )
            const iconDisplayRev =
              index === 0 ? (
                <IconSpeachGrey />
              ) : index === 1 ? (
                <IconGlobeGrey />
              ) : index === 2 ? (
                <IconGraphGrey />
              ) : (
                <IconCloudGrey />
              )
            return (
              <SingleNavItem
                key={index}
                itemActive={isActiveItem}
                onClick={() => {
                  scrollTo(`#item-${index}`)
                  setItemActive(index)
                }}
                className="mainNav__item"
              >
                <div className="mainNav__item--icon">
                  {isActiveItem ? iconDisplay : iconDisplayRev}
                </div>
                <div className="mainNav__item--title">
                  <p key={index}>{service.title}</p>
                </div>
              </SingleNavItem>
            )
          })}
        </div>
        <div className="mainContentWrap">
          {choose.acf._kon_sercho_services.map((service, index) => {
            const isActiveItem = index === itemActive ? true : false
            const iconDisplay =
              index === 0 ? (
                <IconSpeach />
              ) : index === 1 ? (
                <IconGlobe />
              ) : index === 2 ? (
                <IconGraph />
              ) : (
                <IconCloud />
              )
            const iconDisplayRev =
              index === 0 ? (
                <IconSpeachRev />
              ) : index === 1 ? (
                <IconGlobeRev />
              ) : index === 2 ? (
                <IconGraphRev />
              ) : (
                <IconCloudRev />
              )
            return (
              <SingleChooseItem
                id={`item-${index}`}
                itemActive={isActiveItem}
                className="chooseItem"
                key={index}
              >
                <div className="chooseItem__title">
                  <h2>{service.title}</h2>
                </div>
                <div className="chooseItem__icon">
                  {isActiveItem ? iconDisplayRev : iconDisplay}
                </div>
                <div
                  className="chooseItem__content"
                  dangerouslySetInnerHTML={{ __html: service.content }}
                />
                <div
                  className="chooseItem__backTop"
                  onClick={() => {
                    scrollTo(`#chooseYouServices`)
                  }}
                >
                  <button>Back To Top</button>
                </div>
              </SingleChooseItem>
            )
          })}
        </div>
      </div>
    </ChooseSection>
  )
}

export default Choose
