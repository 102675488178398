import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Hero from "../components/TemplateComponets/Services/Hero"
import Choose from "../components/TemplateComponets/Services/Choose"
import BottomHero from "../components/TemplateComponets/Services/BottomHero"

const services = props => {
  const { seoInfo, hero, choose, botHero } = props.data
  const location = props.location
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={location.pathname}
      />
      <Hero hero={hero} />
      <Choose choose={choose} />
      <BottomHero botHero={botHero} />
    </Layout>
  )
}
export const servicesQuery = graphql`
  query servicesPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }
    hero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_serher_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    choose: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_sercho_services {
          title
          content
        }
      }
    }
    botHero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_serbother_bottom_hero {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default services
