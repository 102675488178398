import React from "react"
import BGImage from "gatsby-background-image"
import styled from "styled-components"

const BotHeroImageSection = styled.section`
  position: relative;
  min-height: 60rem;
  margin-bottom: -15rem;
  overflow: hidden;

  @media (min-width: 768px) {
    min-height: 60rem;
  }

  @media (min-width: 1025px) {
    min-height: 75rem;
  }

  .hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__image {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 25%);
    }
  }
`

const BottomHero = ({ botHero }) => {
  return (
    <BotHeroImageSection>
      <div className="hero">
        <BGImage
          tag="div"
          className="hero__image"
          fluid={
            botHero.acf._kon_serbother_bottom_hero.localFile.childImageSharp
              .fluid
          }
        />
      </div>
    </BotHeroImageSection>
  )
}

export default BottomHero
